.clickable {
  cursor: pointer;
}

.modal-overlay {
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100000;
}

.landing-side-panel {
  margin: 0;
  padding: 0;
  width: 20%;
  z-index: 200;
  background-color: black;
  position: fixed;
  height: 100%;
  overflow: auto;
}

.data-input {
  height: 40px;
  margin: 30px 10px 30px 10px;
}

#resume * {
  color: black;
}

.input-group * {
  color: black;
}
